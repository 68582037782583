import { useIntl } from 'react-intl'
import _get from 'lodash/get';
import cx from 'classnames';
import CardTitle from '@/shared/ui/CardTitle';
import RowBlock, { RowList } from '~/Accounts/components/RowBlock';
import CoinLabel from '@/shared/ui/CoinLabel';
import { accountCoins, BTC_COIN } from '@/shared/constants/common';
import { useGetBalanceByCoinQuery } from '~/Accounts/api';

type BalancesProps = {
  account: any;
};

const Balances = (props: BalancesProps) => {
  const intl = useIntl();
  const { account } = props;

  const { data: balanceBTC = 0 } = useGetBalanceByCoinQuery({ account: _get(account, 'name'), coin: 'BTC' }, { pollingInterval: 60000, skip: !(account) });

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'Details.balance', defaultMessage: 'Balance' })} inCard />
      <RowList className='mb-5'>
        {accountCoins.map((coin: string) => {
          const isBTC = coin === BTC_COIN;
          const balance = isBTC ? balanceBTC : _get(account, `${coin}.balances.unpaid`, 0);
          return (
            <RowBlock key={coin}>
              <div className='font-medium'>
                <CoinLabel coin={coin} />
              </div>
              <div className={cx('text-xs')}>
                <CoinLabel
                  coin={coin}
                  isShort
                  hasIcon={false}
                  balance={balance}
                  showZeroBalance={true}
                />
              </div>
            </RowBlock>
          )
        })}
      </RowList>
    </>
  );
}

export default Balances;
